import fb from '../assets/facebook.png'
import fbLogo from '../assets/fb_logo.png'
function Facebook(){
  return(
  <section className="portfolio-item facebook">

    <div className="item-header" animation="grow-in" animation-delay=".5s">
      <div className="left">
        <div className="image-info">
          <img src={fbLogo} alt="facebook logo"/>
        </div>
      </div>
      <div className="right">
        <h2>Facebook Community Boost</h2>
        <h3>A wordpress build to renew Facebook's Community Boost department</h3>
        <ul>
          <li>Ambitious design</li>
          <li>Pages fully customizable with wordpress backend</li>
          <li>Aggressive timeline</li>
    
        </ul>
      </div>
    
    </div>
    
    <div className="item-content sos" sos="true">
      <div className="container column centered">
        <img src={fb} alt="facebook on a laptop"/>
        <a className="button" href="https://fbcommunityboost.com/">Check out the live site</a> 
      </div>
    </div>
    
    <div className="item-content sos" sos="true">
      <div className="container">
        <div className="col-sm-18 col-sm-offset-3">
          <h3>Setup</h3>
          <p>An internal team at Facebook, that advocates small businesses leveraging facebook for the promotion of their business, came to Three29 with the goal of updating their online presence. This meant a new design and a new wordpress build. They wanted to use wordpress VIP, which is more strict on coding standards, and contract a design agency to build out the pages for us. So our responsibility would be strictly to develop this site. We jumped at the opportunity and got to work.</p>
        </div>
      </div>
    </div>
    
    <div className="item-content sos" sos="true">
      <div className="container">
        <div className="col-sm-18 col-sm-offset-3">
          <h3>Approach</h3>
          <p>Facebook had quite a specific and tight deadline that they needed to hit, so we planned our development around dense sprints. We would have standup meetings with Facebook and the design agency every morning. The team at Facebook works out of New York and the designers were in London, so the need for daily standups to keep in communication was critical.</p>
        </div>
      </div>
    </div>
    
    <div className="item-content sos" sos="true">
      <div className="container">
        <div className="col-sm-18 col-sm-offset-3">
          <h3>Action</h3>
          <p>Unlike normal Wordpress builds, Wordpress VIP required a different standard of php coding on our end. While not terribly difficult, it did pose the challenge of having to develop with someone else’s standards in mind. To make sure that these standards were upheld, the VIP team even reviews the code pushed to git after every major new feature comitted. The design that we were given was very ambitious, which was a welcome challenge for myself. There were many design elements that aren’t usually found on the web, much of which was required to be editable by Facebook for future use; I really had to keep the end user in mind while also constructing off of an intricate design.</p>
        </div>
      </div>
    </div>
    
    <div className="item-content sos" sos="true">
      <div className="container">
        <div className="col-sm-18 col-sm-offset-3">
          <h3>Result</h3>
          <p> At the end of the sprints we launched on time and with Facebook happy. The managers at Facebook were very pleased with the upgrade to their site and were quick to commit to working on more projects together. I personally was proud of the work given the complexity of the frontend requirements and also the deadline. </p>
        </div>
      </div>
    </div>
    
    </section>
  )
}
export default Facebook;