import aws from '../assets/aws-cover.png'
import FB from '../assets/FB.jpg'
import mits from '../assets/mits-preview.jpg'
import sunworks from '../assets/sunworks.jpg'
import g1c from '../assets/g1c_preview.jpg'
import safe from '../assets/safe-preview.jpg'
import { Link } from 'react-router-dom'
function Home(){
  return(
  <>
    <div className="home-intro home" animation="slide-in" animation-delay="1s">
    <h1>Chase Sterling Allhart</h1>
    <hr animation="line-grow-from-left" animation-delay="1.2s"/>
    <p>Frontend Developer</p>
  </div>

  <div className="pieces-title-line">
    <h2 animation="slide-in" animation-delay="1.8s">Work</h2>
    <hr animation="line-grow-from-left" animation-delay="2s"/>
  </div>
  <div className="pieces">

    <Link to="elastic-beanstalk" className="piece" animation="slide-in" animation-delay="2.4s" style={{backgroundImage:`url(${aws})`}}>
      <div className="overlay"></div>
      <div className="text">
        <p>Enterprise Console </p>
        <h5>AWS Elastic Beanstalk</h5>
      </div>
    </Link>

    <Link to="facebook" className="piece" animation="slide-in" animation-delay="2.9s" style={{backgroundImage:`url(${FB})`}}>
      <div className="overlay"></div>
      <div className="text">
        <p>Wordpress VIP Theme Build</p>
        <h5>Facebook Community Boost</h5>
      </div>
    </Link>

    <Link to="sunworks" className="piece" animation="slide-in" animation-delay="3.1s" style={{backgroundImage:`url(${sunworks})`}}>
      <div className="overlay"></div>
      <div className="text">
        <p>Single Page Admin App</p>
        <h5>Sunworks Solar</h5>	
      </div>
    </Link>
    <Link to="mitsubishi" className="piece piece-2" animation="slide-in" animation-delay="2.7s" style={{backgroundImage:`url(${mits})`}}>
      <div className="overlay"></div>
      <div className="text">
        <p>Custom Wordpress Job</p>
        <h5>Mitsubishi</h5>
      </div>
    </Link>

    
    <Link to="golden-one-center" className="piece" animation="slide-in" animation-delay="3.1s" style={{backgroundImage:`url(${g1c})`}}>
      <div className="overlay"></div>
      <div className="text">
        <p>Promotional One Page App</p>
        <h5>Golden 1 Center</h5>
      </div>
    </Link>
    <Link to="safe" className="piece piece-1" animation="slide-in" animation-delay="2.5s" style={{backgroundImage:`url(${safe})`}}>
      <div className="overlay"></div>
      <div className="text">
        <p>Custom Frontend Build</p>
        <h5>Safe Credit Union</h5>
      </div>
    </Link>  
  </div>
</>
  )
}
export default Home;