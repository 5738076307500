import {Routes, Route, BrowserRouter, useLocation} from 'react-router-dom'
import './App.scss';
import Header from './components/Header/Header'
import Home from './pages/Home'
import About from './pages/About'
import ElasticBeanstalk from './pages/ElasticBeanstalk';
import Facebook from './pages/Facebook';
import Mitsubishi from './pages/Mitsubishi';
import Sunworks from './pages/Sunworks';
import Safe from './pages/Safe';
import GoldenOneCenter from './pages/GoldenOneCenter';
import { useLayoutEffect } from 'react';

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 


function App() {
  return (
    <BrowserRouter>
    <Wrapper>
      <div className="App">
        <Header/>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/elastic-beanstalk" element={<ElasticBeanstalk/>}/>
            <Route path="/facebook" element={<Facebook/>}/>
            <Route path="/sunworks" element={<Sunworks/>}/>
            <Route path="/mitsubishi" element={<Mitsubishi/>}/>
            <Route path="/safe" element={<Safe/>}/>
            <Route path="/golden-one-center" element={<GoldenOneCenter/>}/>
        </Routes>
        </div>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
