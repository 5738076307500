import { Link } from 'react-router-dom' 
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/Logo.png'
import kk2 from '../../assets/kk-2.png'

function Header(){
  const location = useLocation();
  const textColor = (location.pathname === '/'  || location.pathname === '/about') ? 'black-text' : ''
  console.log(location);
  return(
    <header className={textColor === '' ? 'floating' : ''}>
      <Link to="/" animation="slide-in" animation-delay=".001s">
        <img className="circle" src={textColor === 'black-text' ? Logo : kk2} alt="logo"/>
      </Link>
        {/* if ($url == '/' || strpos($url, "/about") !== false || $url == '/chaseallbee.com/') { */}
      <Link className="bordered" to="/"><p className={textColor} animation="slide-in" animation-delay=".2s">Home</p></Link>
      <Link className="bordered" to="about"><p className={textColor} animation="slide-in" animation-delay=".3s">About</p></Link>
    </header>
  )
}
export default Header;