import me from '../assets/me.jpg'
function About(){
  return(
    <>
    <div class="screen-outline" animation="slide-in" animation-delay=".3s"></div>
    <section class="about" animation="slide-in" animation-delay=".6s">
      <div class="image">
        <img src={me} alt="chase"/>
      </div>
      <div class="about-content">
        <div class="chase-container">
          <h1>About Chase</h1>
          <p>Hey there, friend! I've got 8+ shipping enterprise level frontend applications. My career lives in the cross section of design and technology. Currently working in Seattle for <a href="https://www.slalom.com/" rel="noreferrer" target="_blank">Slalom</a>.</p>
          <p>Fun Chase facts: I have a small dog that I love a great deal. I do a <a href="https://open.spotify.com/show/1ghYuJf0BNNyIFMJuNVPtr">weekly podcast about video games</a>. My favorite beer right now is: <a href="https://cloudburstbrew.com/beer/in-a-perfect-world-ipa/">Cloudburt's 'In A Perfect World IPA'.</a></p>
        </div>
      </div>
    </section>
    </>
  )
}
export default About;